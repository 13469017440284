import { FeatureStateSymbol, LifeCycle, PageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { withDependencies, named, optional, ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ScreenInFactory } from './types'
import { Animations } from 'feature-animations'
import { ScreenInManager } from './ScreenInManager/ScreenInManager'
import { name } from './symbols'
import screenIn from './screenIn'

const screenInFactory: ScreenInFactory = (featureConfig, featureState, animationsProvider) => ({
	pageDidMount: async () => {
		if (!animationsProvider) {
			return
		}
		const animationsManager = await animationsProvider.getInstance()
		const screenInManager =
			(featureState.get() && featureState.get().screenInManager) || new ScreenInManager(animationsManager)
		featureState.update((state) => ({ ...state, screenInManager }))

		screenInManager.init(featureConfig)

		const animations = screenIn({ model: featureConfig, manager: screenInManager })
		animations.start()
	},
})

const ScreenIn = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(FeatureStateSymbol, name), optional(Animations)],
	screenInFactory
)

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageDidMountHandler).to(ScreenIn)
}
