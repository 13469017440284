import {
	FeatureStateSymbol,
	IPageWillMountHandler,
	ViewMode,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import tweenEngine from 'santa-core-utils/dist/cjs/coreUtils/tweenEngine/tweenEngine3'
import { IFeatureState } from 'thunderbolt-feature-state'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { name } from './symbols'
import { getAnimatorManager } from './animations'
import { AnimatorManager, AnimationsPageState } from './types'
import { taskify } from '@wix/thunderbolt-commons'

const getAnimatorManagerInstance = async (viewMode: ViewMode): Promise<AnimatorManager> => {
	const [gsap, ScrollToPlugin, santaAnimations] = await taskify(() =>
		Promise.all([
			import('gsap').then((x) => x.default),
			import('gsap/ScrollToPlugin').then((x) => x.default),
			import('santa-animations').then((x) => x.default),
		])
	)

	return taskify(() => {
		const tweenEngineAndFactory = tweenEngine.create(gsap, [ScrollToPlugin])
		const animator = santaAnimations.create(tweenEngineAndFactory, undefined, viewMode)

		return getAnimatorManager(animator)
	})
}

const animationsInit = (
	featureState: IFeatureState<AnimationsPageState>,
	viewerModel: ViewerModel
): IPageWillMountHandler => {
	return {
		pageWillMount() {
			if (!featureState.get()) {
				const animatorManagerPromise = getAnimatorManagerInstance(viewerModel.viewMode)
				featureState.update(() => ({ animatorManager: animatorManagerPromise }))
			}
		},
	}
}

export const AnimationsInit = withDependencies([named(FeatureStateSymbol, name), ViewerModelSym], animationsInit)
